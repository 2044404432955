.App {
  /*text-align: center;*/
  overflow: hidden;
}

.App-Content {
  background-color: #ffffff;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  color: #3f3f3f;
  /*font-family: poppinsMedium;*/
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.container {
  max-width: 100%;
}

img {
  object-fit: contain;
}

/*********************************  */
/*  */

.Div-Ecosystem .col {
  padding-right: 0px;
  padding-left: 0px;
}

.Div-Ecosystem .Img-Ecosystem-Background {
  width: 100%;
  transform: translateX(85px);
}

.Div-Ecosystem-Description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0px;
  max-width: 550px;
  margin-left: 20px;
  margin-right: 20px;
}

.Img-Logo {
  width: 82px;
  height: 90px;
}

.Img-Eco-Chat {
  width: 513px;
  height: 106px;
  margin-left: -5px;
  margin-top: 50px;
}

.Div-Ecosystem-Description .Img-Logo {
  margin-bottom: 40px;
}

.Div-Ecosystem-Description .Img-Ecosystem {
  width: 290px;
  height: 75px;
}

.Div-Ecosystem-Description .Img-Live-Your-Best {
  width: 500px;
  height: 96px;
  margin-left: -7px;
}

.Div-Ecosystem-Description p {
  margin-top: 30px;
  margin-bottom: 0px;
}

button {
  background-size: cover;
  border-color: transparent;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 2px 5px 20px 0 rgba(21, 36, 57, 0.1);
  /*outline: none;*/
}

button:active,
button:focus {
  box-shadow: 0 0 0 0 rgba(21, 36, 57, 0.1);
  outline: none;
}

.Div-Ecosystem-Description button {
  width: 165px;
  height: 55px;
}

.Div-Ecosystem-Description .App-Store {
  background-image: url("./assets/app_store.png");
}

.Div-Ecosystem-Description .Google-Play {
  background-image: url("./assets/google_play.png");
  margin-left: 10px;
}

.Div-Store {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.Img-Ecosystem-Content {
  position: absolute;
  top: 5vw;
  right: 5vw;
  width: 55vw;
}

.Img-Ecosystem-Content-Mobile {
  display: none;
}

.Text-1 {
  font-size: 2.5rem;
}

.Text-2 {
  font-size: 2rem;
}

.Text-3 {
  font-size: 1.5rem;
}

.Text-4 {
  font-size: 1.2rem;
}

.Text-41 {
  font-size: 1.3rem;
}

.Text-42 {
  font-size: 1.1rem;
}

.Text-5 {
  font-size: 0.8rem;
}

/************************** */

.Community-Member {
  margin-top: 100px;
  padding-left: 50px;
  padding-right: 50px;
}

.Div-Community-Member {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Img-Community-Member {
  width: 350px;
  /*margin-bottom: 100px;*/
}

.Img-Community {
  width: 100%;
  min-width: 300px;
  max-height: 440px;
  /*margin-bottom: 100px;*/
}

.P-Community-Member {
  padding-left: 30px;
  padding-right: 30px;
  min-width: 500px;
}

.Community-Way div {
  padding-right: 0px;
  padding-left: 0px;
}

.Community-Way-Item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 5px 10px 0 rgba(21, 36, 57, 0.1);
}

.Community-Way-Sub {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Community-Way-Sub p {
  position: absolute;
  color: rgba(255, 255, 255, 0.6);
  font-size: 8rem;
  font-weight: 500;
  /*  font-style: normal;*/
}

.Community-Way-Sub img {
  max-width: 250px;
}

/*****************/

.Div-Eco-Tokens {
  margin-top: 100px;
}

.Div-Eco-Tokens .col {
  padding-right: 0px;
  padding-left: 0px;
}

.Div-Eco-Tokens-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-width: 470px;
  min-width: 320px;
  margin-left: 20px;
  margin-right: 20px;
}

.Div-Eco-Tokens-Wrapper img {
  width: 100%;
}

.Div-Eco-Tokens-Category {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.Div-Eco-Tokens-Category .Background {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: contain;
  object-position: right;
  padding-top: 100px;
  padding-left: 100px;
}

.Background-Right {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: contain;
  object-position: right;
  padding-top: 100px;
  padding-bottom: 50px;
  padding-left: 100px;
}

.Div-Eco-Category-Wrapper {
  z-index: 1;
  width: 90%;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 5px 10px 0 rgba(21, 36, 57, 0.1);
  /*min-width: 350px;*/
}

.Div-Eco-Category-Wrapper img {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 30px;
}

.Div-Eco-Category-Wrapper p {
  margin-bottom: 0px;
}

/**************/

.Div-Eco-Values {
  margin-top: 100px;
}

.Div-Eco-ID-Wrapper {
  display: flex;
  justify-content: center;
  height: 675px;
  padding-top: 50px;
}

.Div-Eco-ID-Wrapper img {
  width: 100%;
  max-width: 500px;
  height: 700px;
  z-index: 1;
}

.Div-Eco-ID-Wrapper .Background {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: fill;
}

.Div-Eco-Values-Wrapper img {
  width: 100%;
}

.Div-Eco-Values-Wrapper .Img-Eco-Values {
  max-width: 573px;
  max-height: 799px;
}

.Div-Eco-Values .col-md-auto {
  padding-left: 0px;
  padding-right: 0px;
}

.Img-Live-Life {
  width: 60%;
  min-width: 350px;
}

.Div-Eco-Values-Content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 655px;
}

.Div-Value {
  margin-right: 20px;
  margin-bottom: 20px;
}

.Div-Value-Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Div-Value-Wrapper img {
  width: 90px;
  height: 90px;
  border-radius: 45px;
  padding: 20px;
  /*background-image: url('./assets/value_background.png');  */
  /*background-size: cover;*/
  background: #faff87;
}

.Div-Value-Wrapper p {
  margin-top: 10px;
  margin-bottom: 0px;
  margin-right: 15px;
  font-size: 4rem;
  font-weight: 500;
  color: #f1f1f1;
}

.Div-Own-Life {
  position: relative;
}

.Div-Own-Life .col-md-auto {
  padding-left: 0px;
  padding-right: 0px;
}

.Div-Own-Life-Content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  /*max-width: 655px;*/
}

.Div-Own-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 340px;
  margin: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 5px 10px 0 rgba(21, 36, 57, 0.1);
}

.Div-Own-Wrapper img {
  width: 50px;
  height: 50px;
}

.Div-Own-Wrapper p {
  margin-bottom: 0px;
  margin-top: 20px;
}

.Div-Plus-Member {
  margin-top: 100px;
  margin-bottom: 100px;
  background-image: url("./assets/bg_left.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}

.Div-How-Much {
  margin-top: 100px;
}

.Img-Save-Per-Month {
  max-width: 502px;
  max-height: 838px;
}

.Div-Save-Content-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Div-Save-Content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 550px;
}

.Div-Save-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  margin: 20px 0px;
}

.Div-Save-Wrapper img {
  width: 50px;
  height: 50px;
}

.Div-Save-Wrapper p {
  margin-bottom: 0px;
  margin-top: 20px;
}

.Div-Earn-Tokens {
  width: 100%;
  max-width: 420px;
}

.Btn-Earn-Tokens {
  width: 200px;
  height: 55px;
  background-color: #faff87;
}

.Div-Plus-Member .Background {
  position: absolute;
  /*z-index: 3;*/
  /*top: 0;*/
}

.Img-Plus-Member {
  width: 100%;
  max-width: 377px;
  margin-left: 120px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.Div-Plus-Content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 840px;
}

.Div-Plus-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 260px;
  margin: 10px;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 5px 10px 0 rgba(21, 36, 57, 0.1);
}

.Div-Plus-Wrapper p {
  margin-bottom: 0px;
  margin-top: 20px;
  font-size: 0.9rem;
}

.Div-Plus-Wrapper img {
  height: 90px;
}

.Div-Bottom {
  padding: 100px;
  /*background-image: url('./assets/bottom.png');  */
  /*background-size: cover;*/
  background: #1e2337;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Div-Bottom p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Div-Bottom img {
  margin-right: 10px;
}

.Div-Bottom .col-md-auto {
  display: flex;
  align-items: center;
}

.best-exp-content {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  position: relative;
}

.best-exp-content .retailers-tab-container {
  display: flex;
  align-items: center;
  width: 600px;
}

.retailer-tab {
  flex: 1;
  margin-bottom: 30px;
  cursor: pointer;
  background: white;
  margin-right: 15px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.retailer-tab.selected {
  background: #faff87;
}

.retailer-tab img {
  width: 40px;
  margin-right: 15px;
}

.retailer-tab:last-child {
  margin-right: 0;
}

.retailers-container {
  display: flex;
  flex-wrap: wrap;
  width: 560px;
  padding-left: 10px;
}

.retailer-item {
  width: 90px;
  height: 90px;
  margin: 0 20px 20px 0;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.retailer-item:nth-child(5n + 0) {
  /*margin-right: 10px;*/
}

.retailer-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Img-EcoPay-Phone {
  max-width: 450px;
  min-width: 200px;
  width: 100%;
}

.Div-EcoPay {
  display: flex;
  flex-direction: column;
}

.Img-EcoPay {
  width: 200px;
  height: 51px;
}

.Div-EcoPay-Options {
  display: flex;
  flex-direction: column;
}

.Div-EcoPay-Option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Div-EcoPay-Option img {
  width: 25px;
  height: 25px;
}

.Div-EcoPay-Option p {
}

.ecopay-img {
  width: 330px;
  height: 660px;
}

.img-girl-with-dog {
  margin-top: 321px;
  width: 183px;
  height: 339px;
}

.C-Crypto {
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}

.Background-Left {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: contain;
  object-position: left;
  padding-top: 100px;
  padding-bottom: 50px;
}

.Div-Token-Wrapper {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  width: 350px;
  height: 100%;
  padding-top: 60px;
}

.Div-Crypto {
  max-width: 500px;
}

.Div-Crypto img {
  width: 100%;
}

.Img-Crypto {
  width: 60px;
  height: 60px;
}

.Div-Crypto-Token {
  /*width: 100%;*/
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin: 0px 20px 20px;
  background: #415075;
  border-radius: 30px;
}

.Img-Token {
  width: 50px;
  height: 50px;
}

.Div-Token {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*width: 100%;*/
  padding: 10px 20px;
  margin: 0px 20px 20px;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
}

.C-EcoID {
  position: relative;
  margin-top: 100px;
}

.Img-Eco-ID-Right {
  position: absolute;
  z-index: 0;
  right: 0px;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  object-fit: contain;
  object-position: right;
}

.Div-Eco-ID-Right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Div-Eco-ID-Right .Img-Eco-ID {
  width: 150px;
  height: 60px;
  margin-right: 60px;
}

.Div-Eco-ID-Right .Img-Eco-ID-Phone {
  width: 400px;
  max-height: 709px;
}

.Div-Eco-ID-Desktop-Category {
  display: flex;
  flex-direction: row;
  padding-bottom: 100px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Div-Eco-ID-Desktop-Wrapper {
  z-index: 1;
  width: 47%;
  margin-top: 30px;
  padding: 30px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 5px 10px 0 rgba(21, 36, 57, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Div-Eco-ID-Desktop-Wrapper img {
  width: 50px;
  height: 50px;
}

.Div-Eco-ID-Desktop-Wrapper p {
  margin-bottom: 0px;
}

.Div-Section-Item {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 5px 10px 0 rgb(21 36 57 / 10%);
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 20px;
}

.Img-Item-Top {
  position: absolute;
  top: -150px;
  width: 120px !important;
  height: 120px !important;
}

.Div-Section-Item img {
  height: 60px;
  object-fit: contain;
  margin-top: 80px;
}

.Div-Item-Member {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
}

.Div-Item-Member img {
  height: 60px;
  margin-top: 0px;
}

.Div-Item-Member p {
  margin: 0px;
  font-style: italic;
  font-weight: 700;
}

.primary-btn {
  cursor: pointer;
  font-family: "Gothic A1", sans-serif;
  background-color: #faff87;
  font-size: 14px;
  /*font-weight: 600;*/
  color: #434343;
  height: 55px;
  width: 200px;
  outline: none !important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px 6px 1px rgba(225, 230, 121, 0.45);
}

.primary-btn:hover {
  background-color: #faffd0;
}

.primary-btn:active {
  background-color: #fad340;
}
.heart--small {
  height: 2rem;
}
.heart--medium {
  height: 2.5rem;
}
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.Div-Eco-Store-Desktop-Category {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.Div-Eco-Store-Desktop-Wrapper {
  z-index: 1;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 5px 10px 0 rgba(21, 36, 57, 0.1);
  min-width: 200px;
}
.Text-branding {
  margin-top: 75px;
  font-weight: bold;
}
.C-EcoChat {
  position: relative;
  padding: 0;
}
.Img-Eco-ID-Left {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: contain;
  object-position: left;
  padding-top: 100px;
  padding-bottom: 50px;
}

.Div-Eco-Store-Desktop-Wrapper img {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 30px;
}

.Div-Eco-Store-Desktop-Wrapper p {
  margin-bottom: 0px;
}
.postcode-input {
  margin-left: 30px;
  width: 250px;
  border-radius: 7px;
  border-color: #80808069;
  padding: 2px 5px;
  text-align: center;
  line-height: 30px;
}
.postcode-input:focus {
  border-radius: 7px;
  border-color: #80808069;
  outline-width: 0;
}
.Div-Eco-ID-Desktop-Wrapper {
  border-radius: 43px !important;
}

@media screen and (max-width: 1024px) {
  .Text-1 {
    font-size: 1.7rem;
  }
  .Text-2 {
    font-size: 1.4rem;
  }
  .Text-3 {
    font-size: 1.1rem;
  }
  .Text-4 {
    font-size: 1.1rem;
  }
  .Text-41 {
    font-size: 1rem;
  }
  .Text-42 {
    font-size: 0.9rem;
  }
}
.Div-Value-Wrapper + .text-right {
  position: relative;
  left: 15px;
}

@media screen and (max-width: 768px) {
  .retailers-container {
    margin-bottom: 125px !important;
  }
  .Div-Eco-ID-Desktop-Category {
    padding-bottom: 50px;
  }
  .Img-Item-Top {
    margin-top: 20px !important;
  }
  .Img-Eco-Chat {
    margin-top: 10px;
  }
  .postcode-input {
    width: 80%;
    font-size: 0.8rem;
    margin-top: 5px;
    margin-left: 0;
    margin-right: 0;
  }
  .heart--small {
    width: 15px;
  }
  .heart--medium {
    width: 20px;
  }
  .Div-Ecosystem-Description .Img-Live-Your-Best {
    width: 300px;
    height: 90px;
    margin-top: 10px !important;
    margin-left: 0px;
  }
  .Img-Eco-Chat {
    width: 300px;
    height: 75px;
    margin-left: 0;
  }
  .Community-Member {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .P-Community-Member {
    padding: 0;
    margin-top: 20px;
    text-align: center;
    min-width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .Div-Eco-Tokens {
    margin-top: 50px;
  }
  .Div-Eco-Category-Wrapper {
    width: 100%;
    padding: 15px;
  }
  .Div-Token-Wrapper {
    padding-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  .Div-Ecosystem-Description {
    align-items: center;
  }
  .Div-Ecosystem-Description p {
    text-align: center;
    margin-top: 20px;
  }
  .Col-Ecosystem-Background {
    display: none;
  }
  .Div-Ecosystem-Description .Img-Logo {
    display: none;
  }
  .Img-Ecosystem-Content {
    display: none;
  }
  .Img-Ecosystem-Content-Mobile {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
  .best-exp-content {
    flex-direction: column;
    padding: 0px 10px;
    margin-top: 0px !important;
  }
  .best-exp-content .retailers {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .retailers .retailers-tab-container {
    flex-direction: column;
    width: unset;
    max-width: 400px;
    width: 100%;
  }
  .retailers-tab-container .retailer-tab {
    flex: unset;
    width: 90%;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .retailers-container {
    width: 100%;
    margin-top: 50px;
    justify-content: center;
  }
  .retailer-item:nth-child(5n + 0) {
    margin-right: 20px;
  }
  .retailer-item:nth-child(2n + 0) {
    margin-right: 20px;
  }
  .ecopay-img {
    width: 240px;
    height: 480px;
    margin: 50px auto 0;
  }
  .img-girl-with-dog {
    display: none;
  }
  .Div-Save-Wrapper {
    width: 150px;
  }

  .Div-Eco-Tokens-Category {
    padding-bottom: 50px;
  }

  .Div-EcoPay {
    justify-content: center;
    align-items: center;
  }

  .Background-Right {
    display: none;
  }

  .Background-Left {
    display: none;
  }

  .Img-Eco-ID-Right {
    display: none;
  }
  .Img-Eco-ID-Left {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .Div-Value {
    width: 140px;
  }
  .Div-Value p {
    font-size: 0.8rem;
  }
  .Div-Value-Wrapper p {
    font-size: 3rem;
  }

  .Div-Value-Wrapper img {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 1024px) {
  .Div-Own-Life-Content {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .Img-Plus-Member {
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .Div-Bottom {
    padding: 20px;
  }
  .Div-Plus-Member {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .Div-How-Much {
    margin-top: 50px;
  }
}

.Div-Eco-ID-Desktop-Wrapper--no-border {
  border-radius: 0 !important;
}
.retailer-item {
  width: 70px;
  height: 70px;
  padding: 7px;
}
.join-now {
  width: auto !important;
  height: auto !important;
  padding: 7px 15px;
  background: rgb(248, 255, 116);
}
.Div-Ecosystem-Description {
  margin: 7px 15px;
}

.Header {
  display: flex;
  justify-content: space-between;
}
.Header button,
.content-div button {
  padding: 10px 20px;
  border: 2px solid #0000003b;
  width: 125px;
  font-size: 20px;
  font-weight: bold;
}
.content-div {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(250, 255, 135, 1) 100%
  );
}
.content-div > p {
  width: 100%;
  text-align: center;
}

.rb-card {
  position: relative;
  background: white;
  box-shadow: 2px 5px 10px 0 rgb(21 36 57 / 10%);
  border-radius: 20px;
  min-height: 450px;
  margin-top: 75px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.rb-card > * {
  text-align: center;
}
.rb-card-header {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background: white;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px 0 rgb(21 36 57 / 24%);
}
.Div-Bottom {
  position: static;
  bottom: 0;
  padding: 50px;
  margin-top: 100px;
}


.logo {
  height: 70px;
}

.rb-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rb-card {
  display: block;
}

.rb-card > p :nth-child(1) {
  height: 100px;
  padding-top:10px;
}
.rb-card > p:nth-child(2) {
  height: 100px
}
.rb-card > p:nth-child(3) {
  height: 100px;
}
@media screen and (max-width: 750px) {
  .rb-card {
    min-height: 200px;
  }
  .rb-card > p :nth-child(1) {
    height: 50px;
    padding-top:10px;
  }
  .rb-card > p:nth-child(2) {
    height: 50px
  }
  .rb-card > p:nth-child(3) {
    height: 50px;
  }
}