body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  /*background-color: #f4f6fa;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: poppinsBlack;
  src: url('./assets/fonts/Poppins/Poppins-Black.ttf');
}

@font-face {
  font-family: poppinsBold;
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: poppinsSemiBold;
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: poppinsMedium;
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: poppinsLight;
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: poppinsRegular;
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}

.poppins-medium {
  font-family: poppinsMedium !important;
}

.poppins-bold {
  font-family: poppinsBold !important;
}

.poppins-semibold {
  font-family: poppinsSemiBold !important;
}

.poppins-black {
  font-family: poppinsBlack !important;
}

.poppins-light {
  font-family: poppinsLight !important;
}

.poppins-regular {
  font-family: poppinsRegular !important;
}


